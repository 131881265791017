.nav{
    background-color: #1D9BF0;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 100;
}

.nav a{
    text-decoration: none;
}

.nav img{
    width: 40px;
    margin-left: 15vw;
}

.nav__actions{
    display: flex;
    width: 40%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.nav__actions p{
    margin-right: 30px;
    font-weight: bold;
    color: white;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.nav__actions p:hover{
    cursor: pointer;
    color: rgba(232, 232, 232, 0.78);
    transition: 0.2s;
}