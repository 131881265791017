.post{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
    max-width: 400px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
}

.post hr{
    width: 100%;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
}

.post__pic{
    width: 100%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.post__user{
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 13px;
}

.post__user img{
    width: 50px;
    height: 48px;
    border-radius: 50%;
}

.post__user__details{
    margin-left: 10px;
}

.post__content{
    margin: 10px 0 10px 0;
    font-size: 22px;
    width: 100%;
    height: auto;
}

.post__buttons{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.post__buttons button{
    border-radius: 10px;
    width: 40%;
    height: 30px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.post__buttons button:hover{
    background-color: rgba(0, 0, 0, 0.29);
    transition: 0.2s;
    cursor: pointer;
}

.post__buttons__votes,.post__buttons__comments{
    border-radius: 10px;
    width: 40%;
    height: 30px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.post__buttons__votes p,.post__buttons__comments p{
    margin: 2px;
}

.post__comments{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.post__comments hr{
    width: 100%;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
}

.post__comments img{
    width: 30px;
    height: 28px;
    border-radius: 50%;
    margin: 5px;
}

.post__comments__area__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5px;
}
.post__comments__area__container img{
    width: 30px;
    height: 28px;
    border-radius: 50%;
    margin: 5px;
}
.post__comments__area__container button,.post__comments__more button{
    border-radius: 20px;
    width: 50px;
    height: 30px;
    background-color: transparent;
    border: none;
}

.post__comments__more{
    margin-top: 2px;
    display: flex;
    margin-left: auto;
}

.post__comments__more button{
    width: 100px;
    font-size: 12px;
    height: 25px;
}

.post__comments__area__container button:hover,.post__comments__more button:hover{
    background-color: rgba(0, 0, 0, 0.29);
    transition: 0.2s;
    cursor: pointer;
}

.post__comments__area{
    height: 30px;
    width: 70%;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.29);
    padding-left: 10px;
    font-size: 13px;
}

.post__comments__individual{
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.29);
    border-radius: 20px;
    margin-top: 5px;
}

.post__comments__individual p{
    font-size: 13px;
}
